.table-job-bx {
  .revamp-table {
    &__row span,
    &__row span:last-child,
    &__row div,
    &__row div:last-child {
      width: auto;
      cursor: context-menu;
    }

    &__row div {
      margin-bottom: 8px;
    }

    a {
      color: #1c81ff;
      cursor: pointer;

      &:hover {
        color: #fc8f4f91;
      }
    }
  }
}
