.delete-dialog {
  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: justify;
  }

  .body-proceed-text {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 0;
  }
}

.jobId {
  position: absolute;
  right: 1.2rem;
  margin-top: 0.25rem !important;
  @media screen and (max-width: 768px) {
    margin-top: 0 !important;
  }
}
