.candidates-list-container {
  .list-items {
    background: #ffffff;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 0;
    display: flex;
    padding: 2rem;
    flex-direction: column;
    margin-bottom: 15px;

    &:hover .rewmp-btn {
      visibility: visible;
      opacity: 1;
    }
    .list-item {
      display: flex;
      min-height: 5rem;
      height: auto;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .avatar {
        background: #1c81ff;
        width: 60px;
        height: 60px;
        border-radius: 0;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .info {
        display: flex;
        flex-direction: column;
        width: 65%;
        padding-left: 2rem;
      }
    }
    @media (max-width: 900px) {
      margin-left: 0;
    }
    @media (max-width: 599px) {
      .list-item {
        flex-direction: column;
        .avatar,
        img {
          display: none;
        }
        span {
          margin-bottom: 1rem;
        }
        .info {
          padding: 0;
          width: 100%;
        }
        .bid-value {
          width: 100% !important;
          text-align: center !important;
        }
      }
    }
  }

  .rewmp-btn {
    width: 138px;
    height: 36px;
    font-size: 14px;
    padding: 6px 14px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s, opacity 0.5s linear;
  }

  .status-chip {
    top: 0;
    right: 0;
    span {
      color: #ffffff;
    }

    &.Pending,
    &.Approved {
      span {
        color: #000000;
      }
    }
  }

  .applied-actions {
    display: flex;
    .actions {
      display: flex;
      align-items: center;

      button {
        background: transparent;
        border: 1px solid #e3e3e3;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: #000000;
        outline: none !important;
        box-shadow: none !important;
      }
    }
    .schedule-action {
      border-left: 1px solid #e0e0e0;
      margin-left: 1rem;
      padding-left: 1rem;
      span {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: #000000;
        margin-right: 1rem;
      }

      label {
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: #000000;
      }

      .rewmp-btn-secondary {
        opacity: 1 !important;
        visibility: visible !important;
        width: auto;
        margin: 0 !important;
      }
    }
  }
  .pagination {
    width: 90%;
  }
}
