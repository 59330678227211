.social-share {
  .share-list {
    position: absolute;
    width: 10rem;
    right: 0rem;
    background: #ffffff;
    box-shadow: 0px 2px 6px #00000040 !important;
    border-radius: 0;
    padding: 1rem 0.5rem;

    button {
      display: flex;
      align-items: center;
      margin-top: 1rem;
    }
  }
}
.manage-job-left-pane .social-share {
  position: absolute;
  right: 1rem;
}

.manage-jobs-container .social-share .share-list {
  z-index: 9;
  right: 10rem;
}

.manage-jobs-container .social-share .share-icon {
  display: none;
}

.manage-job-left-pane .social-share .share-title {
  display: none;
}
