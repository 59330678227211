.manage-jobs-container {
  .list-items {
    background: #ffffff;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 0;
    display: flex;
    padding: 2rem;
    flex-direction: column;
    position: relative;
    margin-bottom: 15px;
    @media (max-width: 900px) {
      margin-left: 0;
    }

    &:hover .rewmp-btn {
      visibility: visible;
      opacity: 1;
    }

    .list-item {
      display: flex;
      height: 6rem;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;

      .avatar {
        _background: #1c81ff;
        width: 60px;
        height: 60px;
        border-radius: 0;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .info {
        display: flex;
        flex-direction: column;
        width: 65%;
        padding-left: 2rem;
      }

      .job-date-loc {
        color: #999999;
        font-size: 13px;

        .dot-split {
          color: #d9d9d9;
          padding: 0 0.5rem;
          font-size: 24px;
          position: relative;
          top: 6px;
        }
      }

      .badge-new {
        width: 43px;
        height: 22px;
        background: #1c81ff;
        border-radius: 0;
        font-size: 12px;
        color: #ffffff;
        padding: 0.25rem 0.5rem;
        position: relative;
        top: -2px;
      }
    }

    @media (max-width: 599px) {
      flex-direction: column;
      align-items: center;

      .list-item {
        height: auto;
        flex-direction: column;
        align-items: center;
      }

      .status-chip {
        top: 0;
      }

      .info {
        width: 100% !important;
        margin-bottom: 2rem;
      }

      .job-date-loc {
        display: flex;
        flex-direction: column;
        margin: 2rem 0;
      }

      h4 {
        width: auto !important;
        text-align: center;
      }
    }
  }

  .status-chip {
    right: 0;
    top: 0;
    &.active,
    &.closed {
      color: #ffffff;
    }
  }

  .rewmp-btn {
    width: 138px;
    height: 36px;
    font-size: 14px;
    padding: 6px 14px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s, opacity 0.5s linear;
  }
}

.mange-job-details {
  position: relative;
  margin-top: -3rem !important;
  z-index: 997;
}

.post-a-job {
  margin-top: 4rem !important;
  .MuiGrid-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .side-bar-comp {
      display: none;
    }
  }
}

.overflow-table-scroll {
  .MuiTabs-scroller {
    overflow: auto !important;
  }
  .job-description {
    background: #ffffff;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 0;
    margin-top: 2rem;
    padding: 2rem;
    font-size: 15px;
    overflow-x: auto;
  }
}
.manage-job-left-pane {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 0;
  margin-top: 1rem;
  padding: 2rem 1rem;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .job-title {
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      color: #000000;
    }
    .job-sub-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #1c81ff;
    }
  }

  .update-job {
    svg {
      g {
        opacity: 1;
      }
      path {
        stroke: #ffffff;
      }
    }
  }

  .my-jobs_btn-actions {
    display: flex;
    justify-content: space-evenly;
  }
}
