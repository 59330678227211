.stepperContainer {
  margin-left: 102px;
  .MuiStepConnector-root {
    border-left: "4px solid black";
  }
  .stepLabelContainer {
    .MuiStepLabel-iconContainer {
      padding-right: 15px;
      .MuiSvgIcon-root {
        color: black;
      }
    }
  }
}
