.subs-container {
  .MuiGrid-container {
    padding-top: 0;
  }
  .plan-title {
    padding: 5px 25px 5px 25px;
    border-radius: 0;
    margin-top: 1rem;
    font-size: 13px;
  }
  .Starters-title {
    background-color: #4fce99;
    color: #fff;
  }
  .Standard-title {
    background-color: #2895fa;
    color: #fff;
  }
  .Premium-title,
  .Unlimited-title {
    background-color: #f0d48c;
    color: #222222;
  }
  .subs-amount {
    font-weight: 800;
    font-size: 48px;
    text-align: center;
    color: #000000;
    margin-top: 0.5rem;
  }
  .subs-duration {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #747474;
    margin: 0;
  }

  &.new-layout {
    margin-bottom: 4rem;
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      padding: 1rem 2rem;
      border: 1px solid #cfe2f3;
      box-shadow: none;
    }

    .plan-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .features {
      p {
        margin-bottom: 0.5rem;
      }
    }

    .auto-renewal {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .renewal-tiles {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .subs-amount {
        font-weight: normal;
      }

      .pay-tile {
        border-radius: 0;
        padding: 1rem;
        border: 1px solid #dbdbdb;

        button {
          background-color: transparent;
          color: #222222;
        }

        &:first-child {
          margin-right: 2rem;
        }
        &.isActive {
          background: linear-gradient(173.72deg, #1c81ff 4.42%, black 98.93%);
          box-shadow: 0px 4px 5px 1px #c1c1c159;
          padding: 2rem;

          button {
            background: #ffff;
            color: #222222;
          }

          .subs-amount {
            color: #fff;
          }
          .subs-duration {
            color: #fff;
            opacity: 0.6;
          }
        }
      }
      .MuiFormControlLabel-label {
        font-size: 14px;
        opacity: 0.8;
      }
    }

    @media (max-width: 1200px) {
      .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
        flex-direction: column;
      }
      .plan-container {
        display: block;
      }
      button {
        width: 100%;
      }
    }
  }
}

.paypal-buttons.paypal-buttons-context-iframe.paypal-buttons-label-subscribe {
  margin-top: 3rem;
}

.subscribeCompBody {
  margin-top: 30px;
}
