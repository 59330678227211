.header-v2 {
  a.MuiButtonBase-root {
    text-transform: none;
  }
  .Mui-selected {
    color: #1c81ff !important;
  }

  .MuiTabs-indicator {
    background-color: #1c81ff;
    height: 3px;
    display: none;
  }

  .MuiTabs-flexContainer {
    height: 80px;
  }

  .sticky-header {
    border: 1px solid #eaeaea;
  }

  .profile-pic {
    border-radius: 0;
    width: 40px;
    height: 40px;
    position: relative;
    right: 6px;
  }

  .dropdown-item {
    &:active,
    &:hover {
      background-color: #1c81ff;
      color: #ffff;
    }
  }
  .dropdown-toggle {
    &::after {
      border: solid #989898;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      background: transparent;
    }
    &:hover,
    &:focus {
      &::after {
        border: solid black;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        background: transparent;
      }
    }
  }
  .user-actions {
    position: relative;
    top: 10px;
    button {
      box-shadow: none;
      padding-left: 8px;
    }
    .btn-primary:not(:disabled):not(.disabled):active,
    &.show > .btn-primary.dropdown-toggle {
      background: transparent;
      box-shadow: none !important;
    }
    .user-name {
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      color: #202020;
      padding: 0 1rem;

      span {
        font-size: 12px;
        line-height: 120%;
        color: #989898;
      }
    }
    button {
      background: transparent;
      color: black;
      border: none;
      position: relative;
      top: -8px;

      &.btn-success:not(:disabled):not(.disabled):active,
      &.btn-success.dropdown-toggle {
        background: transparent !important;
        color: black;
        box-shadow: none;
      }
    }
  }

  .hide-tab-select {
    .MuiTabs-indicator.css-7s95n2-MuiTabs-indicator {
      background-color: transparent !important;
    }
    a {
      color: rgba(0, 0, 0, 0.6) !important;
    }
  }
}
