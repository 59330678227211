.custom-swiper-scrollbar {
  .swiper-wrapper {
    margin-top: 124px;
  }
  .swiper-scrollbar {
    top: 0;
    bottom: auto;
    border: 1px solid #8e8e8e;
    background-color: #8e8e8e;
  }
  .swiper-scrollbar-drag {
    border: 2px solid #ffffff;
  }
}
