.tox-statusbar__branding,
.tox-statusbar__path .tox-statusbar__path-item {
  display: none !important;
}

.tox-tinymce {
  border: 1px solid #adadad !important;
  border-radius: 0 !important;
  width: 100%;
}

.tox-editor-header {
  box-shadow: none;
  border-bottom: 1px solid #adadad !important;
}
