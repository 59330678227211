.MuiGrid-root .MuiButtonBase-root.MuiTab-root.Mui-selected {
  background: #ffffff;
  border-bottom-color: #ff6600;
  color: #202020;
  font-weight: 500;
}

.MuiTabs-indicator.css-7s95n2-MuiTabs-indicator {
  background-color: #1c81ff !important;
}

.MuiGrid-root .MuiButtonBase-root.MuiTab-root {
  font-size: 15px;
  color: #d2d2d2;
  font-weight: 400;
}

.MuiGrid-root .MuiTabPanel-root {
  padding: unset;
}

.MuiTypography-root.MuiBreadcrumbs-root {
  border-radius: 0;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  background-color: #fff;
  padding: 16px;
}
