.accordion {
  button {
    background: #ffffff;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #eaeaea;
    width: 100%;
    text-align: left;
    height: 64px;
    padding: 0 2rem;
    color: #000000;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    svg {
      transform: rotate(-180deg);
    }

    &.collapsed {
      border-bottom: none;

      svg {
        transform: rotate(360deg);
      }
    }

    &.rewmp-btn {
      width: auto;
      height: auto;
      padding: 0.5rem 1rem;
      background: #1c81ff;
      color: #ffff;
      font-size: 14px;
    }
  }

  .accordion-header {
    margin: 0;
  }

  .accordion-item {
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: 0;
    margin-bottom: 2rem;

    .accordion-body {
      padding: 0 2rem 2rem;
    }
  }

  .plan-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;

    span {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #222222;
    }
  }

  .plan-body {
    height: auto;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 0;
    margin-bottom: 1.5rem;
    padding: 1rem 2rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-weight: 700;
        font-size: 18px;
        color: #3d3d3d;

        .name {
          font-weight: 400;
          font-size: 13px;
          color: #000000;
        }
      }

      .rate {
        display: flex;

        .rate-value {
          text-align: right;
          padding-right: 1rem;

          .amount {
            font-weight: 700;
            font-size: 24px;
            text-align: center;
            color: #000000;
          }

          .duration {
            font-size: 14px;
            text-align: center;
            color: #747474;
          }
        }

        .rate-actions {
          padding-top: 0.25rem;
        }
      }
    }
  }
}

.frequency {
  position: absolute;
  top: 3rem;
  color: #b2acac;
  font-size: 13px;
  right: 1rem;
}

.add-feature-container {
  .action {
    display: flex;
    width: fit-content;
    cursor: pointer;

    .label {
      color: #1c81ff;
    }
  }

  &.actions-disabled {
    .action {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.features-popup {
  padding: 1rem;
  border: 1px solid #cccc;
  border-radius: 0;
  margin-bottom: 1rem;
}

.subs-comp-modal-old {
  .MuiPaper-root.MuiDialog-paper {
    min-width: 50vw !important;
  }
}

.subs-comp {
  .MuiPaper-root.MuiDialog-paper {
    min-width: 40vw !important;
  }
}
