.docs {
  &_container {
    background-color: #fff;
    border-radius: 0;
  }
  .upload {
    position: relative;

    .drag-container {
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;

      .drag-container {
        z-index: 1;
        background: #007bff21;
      }
    }
    &__drop-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 23.5rem;
      border: 0.125rem dashed #0275d8;
      border-radius: 0;

      &.drag-over {
        opacity: 0.5;
        position: relative;
        z-index: 999;
        background-color: red;

        * {
          z-index: 1;
        }

        .a-upload__drop-box * {
          pointer-events: none;
        }
      }
    }

    &__drop-box {
      width: 100%;
      height: 23.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;

      &__label-folder {
        color: gray;
        padding: 1rem 1rem 0;
        font-size: 1rem;
        font-weight: 500;
      }

      &__label-or {
        color: gray;
        font-size: 1rem;
        padding: 2rem;
      }

      &__label-footer {
        position: absolute;
        bottom: 1rem;
        font-size: 0.778rem;
        font-weight: 400;
      }

      svg {
        width: 2.778rem;
        height: 2.778rem;
      }

      &__btn-container {
        &__browsefromcomputer {
          background-color: white !important;
        }

        &__browsefromemployeebtn {
          width: auto !important;
          padding: 0 1.1111rem !important;
          margin-left: 1.6667rem;
        }
      }
    }

    &__label {
      position: absolute;
      color: blue;
      top: -2rem;
      left: 0;
      font-size: 1rem;
    }

    &__progress {
      display: flex;
      align-items: center;
      width: 70%;
      background-color: white;
      position: relative;
      padding: 1rem 2rem;
      border-radius: 0;
      box-shadow: 0.5rem 1rem 2rem rgba(112, 128, 144, 0.15);

      &__container {
        display: flex;
        position: relative;
        width: 100%;
        align-items: center;
      }

      &__bar {
        width: 100%;
        height: 0.222rem;
        background-color: #ccc;
        margin-top: 1rem;

        .count {
          position: absolute;
          color: gray;
          right: 2.5rem;
          top: 0.444rem;
          font-size: 0.667rem;
        }

        .label {
          position: absolute;
          top: 0.25rem;
          color: gray;
        }
      }

      &__fill {
        width: 0;
        height: 0.222rem;
        background-color: blue;
        text-align: center;
        line-height: 1.778rem;
        color: black;
        transition: 0.5s ease-in-out;

        &__success {
          background-color: green;
          width: 100%;
        }

        &__error {
          background-color: red;
          width: 100%;
        }
      }
    }

    &__img-preview {
      border-radius: 0;
      position: relative;

      img {
        width: 12rem;
        height: 12rem;
        border-radius: 0;
        border: 0.056rem solid gray;
      }
    }

    &__icon {
      padding: 0.222rem;
      border-radius: 0;
      background: white;
      position: absolute;
      right: -1.333rem;
      cursor: pointer;
    }

    .file-format {
      text-transform: uppercase;
    }
  }

  #browseFromComputerID {
    background-color: white !important;
  }

  .tip {
    margin-top: 3rem;

    svg {
      position: relative;
      top: -2px;
    }

    &__description {
      font-style: normal;
      font-weight: 400;
      font-size: 1.25rem;
      color: gray;
    }

    &__title {
      font-style: normal;
      font-weight: 400;
      font-size: 1.25rem;
      color: gray;
    }
  }

  .button-actions {
    svg {
      width: 1.3333rem;
      height: 1.3333rem;
    }

    button {
      margin: 3rem 0 0;
    }
  }
}
