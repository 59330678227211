.jobs-count {
  display: flex;
  justify-content: space-between;
  .count {
    font-weight: 600;
    font-size: 15px;
    color: #000000;
    // padding-left: 1rem;
    line-height: 3.5;
  }
  span {
    color: #a3a3a3;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }
  .order {
    padding-right: 1rem;
    display: flex;
    align-items: center;
    .dropdown-toggle::after {
      border: solid #989898;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      background: transparent;
    }
    .dropdown {
      button {
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
      }
      button,
      button:hover,
      button:active,
      button:focus {
        background: transparent;
        color: black;
        border: none;
        position: relative;
        outline: none;
        box-shadow: none !important;
      }
    }
  }
}
.job-list {
  .job-tile {
    &:hover {
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      .button-group {
        visibility: visible !important;
        opacity: 1 !important;
      }
    }
    .job-button {
      position: absolute;
      bottom: 1.5rem;
      right: 0;

      .button-group {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.2s, opacity 0.5s linear;
        position: relative;
        top: 1.5rem;
      }

      .job-details-btn {
        background: #222;
        border-radius: 0;

        a {
          color: #fff !important;
        }
      }
    }
    &:hover .job-button {
      visibility: visible;
      opacity: 1;
    }
  }

  .bid-value {
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    span {
      color: #a3a3a3;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .job-date-loc,
  .job-bid-candidate-info {
    color: #999999;
    font-size: 13px;

    .dot-split {
      color: #d9d9d9;
      padding: 0 0.5rem;
      font-size: 24px;
      position: relative;
      bottom: -0.5rem;
    }
  }

  .badge-new {
    width: 43px;
    height: 22px;
    background: #1c81ff;
    border-radius: 0;
    font-size: 12px;
    color: #ffffff;
    padding: 0.25rem 0.5rem;
    position: relative;
    top: -2px;
  }
}

.job-applied {
  position: absolute;
  display: flex;
  bottom: 0;
  right: 2rem;
  color: #1c5931;
  font-size: 14px;
}

.btn-trans {
  background: #e8f2f1 !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  color: black !important;
}

input[type="range"].range-slider.range-slider--primary::-webkit-slider-thumb {
  background: #1c81ff;
  border-radius: 0;
  width: 40px;
  box-shadow: none !important;
}

.applyjob-action {
  padding: 1rem !important;
}
