@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.all-jobs-container {
  margin-top: 6rem;
}

.flx-wrp-none {
  flex-wrap: nowrap !important;
}

.embed-responsive {
  min-height: 30vh;
}

#about-intro {
  .MuiDialog-container {
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
      min-width: 75vw;
    }
  }
}

.gray-border {
  border-radius: 0;
}

.scrolling-content {
  font-family: "Poppins";

  @keyframes growShrink {
    0% {
      width: 55px;
    }

    50% {
      width: 40px;
    }

    100% {
      width: 55px;
    }
  }

  @keyframes float {
    0% {
      transform: translatey(0px) rotate(-180deg);
    }

    50% {
      transform: translatey(-60px) rotate(-90deg);
    }

    100% {
      transform: translatey(0px) rotate(180deg);
    }
  }

  @keyframes floatInverse {
    0% {
      transform: translatey(0px) rotate(180deg);
    }

    50% {
      transform: translatey(40px) rotate(90deg);
    }

    100% {
      transform: translatey(0px) rotate(-180deg);
    }
  }

  margin-top: 5.9375rem;

  .wrap-text {
    text-overflow: ellipsis;
    width: 3.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
  }

  .slick-prev {
    z-index: 1;
    left: 1.5rem;
    top: 2rem;

    &::before {
      content: "\003C";
      color: black;
      font-weight: bold;
      font-family: monospace;
    }
  }

  .slick-next {
    z-index: 1;
    right: 1.5rem;
    top: 2rem;

    &::before {
      content: "\003E";
      color: black;
      font-weight: bold;
      font-family: monospace;
    }
  }

  .landing-page-container {
    position: relative;

    h1 {
      font-size: 3.75rem;
      line-height: 1;
      padding-right: 0;
      color: #000000;
    }

    .banner {
      background-size: cover;
      background-repeat: no-repeat;
      width: 100vw !important;
      background-position: center center;

      .section {
        width: 60%;
      }

      &.view-1 {
        background-image: url("../../images/background/BG_SVG1.svg");
      }

      &.view-2 {
        background-image: url("../../images/background/BG_SVG1.svg");
      }

      &.view-3 {
        background-image: url("../../images/background/BG_SVG1.svg");
      }

      .section {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 6rem;
        min-height: 100vh;

        @media (max-width: 1400px) {
          padding: 3rem;
        }
      }

      .sub {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1rem;
        color: #000000;

        &.sub-s1 {
          position: relative;
          position: relative;
          flex-direction: column;
          align-items: flex-start;

          img {
            position: absolute;

            &.ellipse-img {
              right: 0;
              top: 0.5rem;
              animation: growShrink 5s ease-in-out infinite;
            }

            &.star-img {
              left: -3rem;
              transform: translatey(0px);
              animation: float 5s ease-in-out infinite;
            }
          }
        }

        &.sub-s2 {
          padding-right: 6rem;
          position: relative;

          @media (max-width: 1400px) {
            padding-right: 0 !important;
            top: -3rem;
          }
          @media (max-width: 599px) {
            padding-right: 0 !important;
            top: -2rem;
          }
          img {
            position: absolute;

            &.star-img {
              right: -3rem;
              transform: translatey(0px);
              animation: floatInverse 8s cubic-bezier(0.4, 0, 0.2, 1) infinite;
            }
          }
        }
      }
    }

    .slick-arrow {
      display: none !important;
    }
  }

  // .landing-page-container {
  //     _display: flex;
  //     _height: calc(100vh - 13vh);
  //     _height: 100vh;
  //     width: 100%;

  //     .slick-arrow{
  //         display: none !important;
  //     }

  //     h1 {
  //         font-size: 4rem;
  //         line-height: 1;
  //         padding-right: 3rem;
  //         color: #ffffff;
  //     }

  //     .section-1 {
  //         _background-color: #1C81FF;
  //         width: 50%;
  //         _height: 100vh;
  //         display: flex;
  //         flex-direction: column;
  //         padding: 8rem;
  //         justify-content: space-evenly;
  //         background-size: cover;

  //         &.view-1 {
  //             background: url("../../images/banner-img 01.jpg") no-repeat 100% 100%;
  //         }

  //         &.view-2 {
  //             background: url("../../images/banner-img 02.jpg") no-repeat 100% 100%;
  //         }

  //         &.view-3 {
  //             background: url("../../images/banner-img 03.jpg") no-repeat 100% 100%;
  //         }

  //         .sub {
  //             display: flex;
  //             align-items: center;
  //             justify-content: flex-start;
  //             margin-bottom: 1rem;

  //             &.sub-s1 {
  //                 position: relative;

  //                 img {
  //                     position: absolute;

  //                     &.ellipse-img {
  //                         right: 0;
  //                         top: 0.5rem;
  //                         animation: growShrink 5s ease-in-out infinite;
  //                     }

  //                     &.star-img {
  //                         left: -3rem;
  //                         transform: translatey(0px);
  //                         animation: float 5s ease-in-out infinite;
  //                     }
  //                 }
  //             }

  //             &.sub-s2 {
  //                 padding-right: 6rem;
  //                 position: relative;

  //                 img {
  //                     position: absolute;

  //                     &.star-img {
  //                         right: -3rem;
  //                         transform: translatey(0px);
  //                         animation: floatInverse 8s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  //                     }
  //                 }

  //             }
  //         }
  //     }

  //     .section-2 {
  //         background-color: #c76644;
  //         width: 50%;
  //         _height: 100vh;
  //         display: flex;

  //         .awssld__content>img {
  //             object-fit: unset;
  //         }

  //         .awssld--cubeAnimation .awssld__container {
  //             height: 100vh;
  //         }
  //     }

  //     @media (max-width: 1200px) {
  //         h1 {
  //             font-size: 3rem;
  //             padding-right: 2rem;
  //         }

  //     }

  //     @media (max-width: 1200px) {
  //         flex-direction: column;

  //         h1 {
  //             font-size: 2rem;
  //             padding-right: 2rem;
  //         }

  //         .section-1 {
  //             padding: 4rem;
  //         }

  //         .section-1,
  //         .section-2 {
  //             width: 100%;
  //         }
  //     }
  // }

  .slider-container {
    display: inline-block;
    width: 100%;
    margin: 6rem 0 !important;
    padding: 0 7rem;

    @media (max-width: 768px) {
      margin: 3rem 0 !important;
    }

    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      color: #202020;
    }

    .slick-slider {
      padding: 0 2rem;

      img {
        min-width: 10rem;
        height: 4rem;
      }
    }

    .slick-prev {
      display: none !important;
    }

    .slick-next {
      display: none !important;
    }
  }

  .comp-looking-for {
    display: flex;
    _height: 100vh;
    flex-direction: column;
    justify-content: space-evenly;
    padding-bottom: 0rem !important;
    margin-top: 32px !important;
    margin-bottom: 32px !important;
    padding-left: 96px;
    padding-right: 96px;

    .comp-title {
      letter-spacing: 0.1em;
      color: #202020;
      opacity: 0.3;
      font-weight: 400;
    }
    .flex-row {
      display: flex;
      padding-top: 1rem;
      img {
        padding: 0 15px;
      }
    }
  }

  .custom-slider-container {
    margin: 0 !important;
    padding: 0;
    position: relative;
    left: -4rem;
    top: -1.5rem;
  }

  .top-notch-talents {
    .slider-container {
      margin: 0 !important;
      padding: 0;
    }

    .slick-prev {
      display: block !important;
      left: -1rem;
      top: 6rem;
    }

    .slick-next {
      display: block !important;
      right: -1rem;
      top: 6rem;
    }
  }

  .looking-for-talent {
    background: url("../../images/sales-bg-01.png") no-repeat 100% 100%;
    padding: 2rem 2rem;
    height: 16rem;
    margin: 8rem 4rem;
    border: 1px solid #dfdfdf;
    border-radius: 0;

    @media (max-width: 1350px) {
      background: url("../../images/sales-bg-02.png") no-repeat 100% 100%;
    }

    .title {
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;
      color: #111827;
      width: 30rem;
      text-align: left;
      @media (max-width: 599px) {
        text-align: center;
      }
    }

    .sub-title {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.67);
    }

    svg {
      position: absolute;
      top: 0.5rem;
    }
  }

  .work-flow-container {
    margin: 0 8rem 0;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .nav-pills {
      display: flex;
      background: #fff1eb;
      margin-bottom: 4rem;
      border-radius: 0;
      height: 3.5rem;

      @media (max-width: 768px) {
        .nav-pills {
          width: 100% !important;
        }
      }

      & .nav-link.active,
      & .show > .nav-link {
        color: #fff;
        background-color: #000000;
      }

      .nav-link {
        cursor: pointer;
        text-align: center;
        border-radius: 0;
        padding: 1rem;
      }
    }

    .tab-info {
      display: flex;

      .title {
        font-weight: 600;
        font-size: 14px;
        line-height: 36px;
        color: #1c81ff;
      }

      .header {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 42px;
        color: #000000;
        padding-right: 3rem;
        @media (max-width: 599px) {
          text-align: center;
        }
      }

      ul {
        li {
          list-style: none;
          line-height: 2.5;
        }
      }
    }
  }

  .t-a-c {
    @media (max-width: 599px) {
      text-align: center !important;
    }
  }

  .slick-slide {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .top-notch-talents {
    width: 100%;
    height: auto;
    padding: 3rem 8rem;
    background: linear-gradient(
      180deg,
      rgba(253, 244, 236, 0) 0%,
      rgba(253, 244, 236, 0.265) 14.32%,
      rgba(253, 244, 236, 0.5) 45.83%,
      rgba(253, 244, 236, 0.215) 84.33%,
      rgba(253, 244, 236, 0) 100%
    );

    .title {
      font-weight: 600;
      font-size: 30px;
      line-height: 36px;
      color: #111827;
      margin-bottom: 2rem;
      text-align: center;
    }

    .tiles-container {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      align-items: center;

      .tech {
        width: 13.25rem;
        height: 7.25rem;
        background: #ffffff;
        border: 1px solid #eaeaea;
        box-shadow: 0px 4px 22px rgba(130, 130, 130, 0.12);
        border-radius: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 1rem;
        padding: 4rem 0;

        &-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #111827;
          position: relative;
          top: 0.5rem;
          margin: 0;
        }

        @media (max-width: 768px) {
          width: 5.25rem;
          height: 6rem;
          padding: 0;

          img {
            width: 2.5rem;
          }

          .tech-title {
            font-size: 12px;
          }
        }
      }

      .tiles {
        width: 212px !important;
        height: 208px;
        background: #ffffff;
        border: 1px solid #eaeaea;
        box-shadow: 0px 4px 22px rgba(130, 130, 130, 0.12);
        border-radius: 0;
        margin: 2rem;
        padding: 2rem;

        img {
          position: relative;
          left: -3rem;
        }

        svg {
          position: relative;
          top: 0.5rem;
          left: -1rem;
        }

        p {
          margin: 0.5rem 0;
        }

        .title {
          font-weight: 700;
          font-size: 14px;
          line-height: 40px;
          color: #111827;
        }
      }
    }
  }

  .available-jobs {
    display: flex;
    margin: 0;
    padding: 0 8rem;
    flex-direction: column;

    h1 {
      font-size: 2rem;
    }

    .sub-desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      opacity: 0.48;
    }

    .title {
      font-weight: 500;
      font-size: 15px;
      color: #000000;
    }

    .count {
      color: #1c81ff;
    }

    .jobs-container {
      display: flex;

      .s-2 {
        padding: 0 4rem;

        .slick-next,
        .slick-prev {
          border-radius: 0;
          box-shadow: 0px 4px 12px #0000003d;

          &:before {
            position: relative;
            top: -1px;
          }
        }

        .slick-next {
          right: -5rem;
          top: 8rem;
        }

        .slick-prev {
          left: -5rem;
          top: 8rem;
        }

        .job {
          width: 16rem !important;
          height: 18rem;
          background: #ffffff;
          border: 1px solid #eaeaea;
          box-shadow: 0px 4px 22px rgba(130, 130, 130, 0.12);
          border-radius: 0;
          padding: 1.5rem;

          &-view-more {
            display: flex !important;
            align-items: center;
            justify-content: center;
            background: transparent;
            border: none;
            box-shadow: none;
          }

          h3 {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #000000;
            white-space: nowrap;
          }

          h6 {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #1c81ff;
          }

          .badge-new {
            width: 34px;
            height: 17px;
            background: #1c81ff;
            border-radius: 0;
            font-size: 9px;
            color: #ffffff;
            padding: 0.15rem 0.25rem;
            position: relative;
            top: -9px;
          }

          .job-chip {
            .MuiChip-filledDefault {
              width: 5rem;
              height: 24px;
              font-size: 11px;
            }
          }

          .dot-split {
            bottom: 0;
          }

          .bid-value {
            font-weight: 500;
            font-size: 18px;
            color: #000000;
          }

          .bid-rate {
            font-weight: 400;
            font-size: 13px;
            color: #a3a3a3;
          }
        }
      }
    }
  }
  .terms-div {
    display: flex;
    flex-direction: column;
  }

  .terms {
    margin: 0 8rem 0;
    height: auto;
    .ul,
    li {
      list-style-type: none;
    }
    p {
      text-align: justify;
      text-justify: inter-word;
    }
  }

  .why-findpro,
  .essential-features,
  .benefits {
    display: flex;
    margin: 6rem 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 48px;
    margin-bottom: 48px;
    @media (max-width: 768px) {
      margin: 3rem 0 !important;
    }

    .header {
      font-weight: 600;
      font-size: 30px;
      line-height: 48px;
      color: #000000;
    }

    .why-container {
      background: #ffffff;
      border: 1px solid #eaeaea;
      box-shadow: 0px 4px 22px rgba(130, 130, 130, 0.12);
      border-radius: 0;
      display: flex;
      justify-content: space-between;
      padding: 3rem;

      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 27px;
        color: #1c81ff;
      }

      img {
        position: relative;
        left: -1rem;
      }

      .header {
        font-weight: 600;
        font-size: 36px;
        line-height: 54px;
        color: #000000;
      }

      .sub-header {
        font-size: 13px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.67);
        width: 13rem;
      }

      .split {
        border: 0.15px solid #0000004f;
        margin: 0 4rem;
        background: #0000004f;
        height: 10rem;
        position: relative;
        top: 2rem;
        opacity: 0.12;
      }
    }
  }

  .essential-features,
  .benefits {
    margin-bottom: 32px;
    margin-top: 32px;
    padding: 0 8rem 4rem;

    @media (max-width: 599px) {
      margin: 8rem 0 4rem;
    }

    .header {
      text-align: center;

      .title {
        color: #1c81ff;
      }
    }

    .essential-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .feat {
        display: flex;
        align-items: center;

        .split {
          width: 1px;
          height: 34px;
          background: #d9d9d9;
          display: inline-block;
          position: relative;
          right: -2rem;
          top: 1.5rem;

          @media (max-width: 1200px) {
            display: none;
          }
        }
      }

      .feat-title {
        width: 100px;
        font-weight: 500;
        font-size: 13px;
        text-align: center;
        color: #222222;
        position: absolute;
      }

      .animate__animated {
        flex-grow: 1;
        margin: 2rem 2rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 599px) {
          margin: 1rem 0;

          .feat-title {
            position: relative;
          }
        }
      }
    }
  }

  .benefits {
    background: #f6f6f6e5;
    padding: 2rem 8rem;
    margin: 0;

    @media (max-width: 768px) {
      padding: 6rem 1rem;
      .num-count {
        left: 14rem;
      }
    }

    .essential-container {
      .feat {
        background: #ffffff;
        border: 1px solid #eaeaea;
        box-shadow: 0px 4px 22px #8282821f;
        border-radius: 0;
        padding: 2rem;
        max-width: 20rem;
        height: 20rem;
        position: relative;
      }
      .feat-title {
        position: relative;
        width: fit-content;
        text-align: left;
        font-weight: 600;
        font-size: 18px;
        color: #222222;
      }
      .feat-desc {
        font-weight: 400;
        font-size: 1rem;
        color: #000000ab;
      }
      img {
        position: relative;
        left: -1rem;

        &.num-count {
          position: absolute;
          top: 1rem;
          left: 16rem;
        }
      }

      @media (max-width: 768px) {
        img.num-count {
          left: 15.5rem;
        }
      }

      @media (max-width: 599px) {
        img.num-count {
          left: 15rem;
        }
      }
    }
  }

  .testimonials {
    background: url("../../images/testimonial-bg.jpg") no-repeat 100% 100%;
    padding: 2rem 16px;
    background-size: cover;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .title {
      font-weight: 700;
      font-size: 30px;
      line-height: 54px;
      text-align: center;
      color: #272d4e;
    }

    .card {
      background: #ffffff;
      border-radius: 0;
      height: 20rem;
    }

    .card-bg {
      z-index: 1;
      top: 11.5rem;
      width: 48% !important;
    }

    button {
      border-radius: 0;
      box-shadow: 0px 4px 12px #0000003d;
    }

    .card-testimonial {
      z-index: 2;
    }

    .slick-slider {
      position: relative;

      .testimonial {
        background: url("../../images/quote.png");
        background-repeat: no-repeat;
        background-position: center top;
        height: 16rem;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      button {
        position: absolute;
        background: white;

        &::before {
          position: relative;
          top: -1px;
        }
      }

      .slick-prev {
        left: -2rem;
        top: 9rem;
      }

      .slick-next {
        top: 9rem;
        right: -2rem;
      }
    }

    .slick-slide {
      height: 19rem;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        text-align: center;

        &.description {
          font-weight: 500;
          font-size: 18px;
          line-height: 30px;
          text-align: center;
          color: #000000;
          padding: 2rem 2rem 0;
        }

        &.category {
          font-weight: 600;
          font-size: 15px;
          line-height: 30px;
          text-align: center;
          color: #1c81ff;
        }
      }
    }
  }

  .latest-blogs {
    padding: 2rem 2rem;
    height: 40rem;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;

    .slider-container {
      .slick-prev {
        display: block !important;
        left: -2rem;
        top: 10rem;
      }

      .slick-next {
        display: block !important;
        right: -2rem;
        top: 10rem;
      }
    }

    .blog {
      position: relative;
      width: 250px !important;
      height: 320px;
      border-radius: 0;
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      color: #fff;
      padding: 0 2rem;
      background: #000;
      background-size: cover !important;

      &.b1 {
        background: url("../../images/blogs/blog1.svg") no-repeat 100% 100%;
      }

      &.b2 {
        background: url("../../images/blogs/blog4.svg") no-repeat 100% 100%;
      }

      &.b3 {
        background: url("../../images/blogs/blog3.svg") no-repeat 100% 100%;
      }

      &.b4 {
        background: url("../../images/blogs/blog4.svg") no-repeat 100% 100%;
      }

      &-description {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
        opacity: 0.56;
      }

      p svg {
        position: relative;
        top: 8px;
      }

      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #000000d4;
        opacity: 0.7;
        border-radius: 0;
        border: 1px solid #ffffff;
        z-index: 0;
      }

      p {
        position: relative;
      }
    }

    .title {
      font-weight: 700;
      font-size: 30px;
      line-height: 54px;
      text-align: center;
      color: #272d4e;
      position: absolute;
      top: 1rem;

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #000000;
        position: relative;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    background: #1c81ff;
    position: relative;

    img {
      width: 66px;
      position: absolute;
      right: 2rem;
      top: 8.75rem;
    }

    &-section-1 {
      display: flex;
      padding-left: 240px;
      padding-right: 240px;
      padding-top: 16px;
      padding-bottom: 16px;

      .s-s1 {
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 54px;
        color: #ffffff;
        width: 50rem;
      }

      .s-s2 {
        display: flex;
        align-items: center;
      }
    }

    &-section-2 {
      display: flex;
      flex-direction: column;
      background: #170c07;
      padding-top: 32px;
      padding-right: 48px;
      padding-bottom: 32px;

      .copy-right {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #afafaf;

        a {
          text-decoration: underline;
          color: #afafaf;
        }
      }

      .info {
        display: flex;

        &-1 {
          width: 75%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;

          &-details {
            display: flex;
            flex-direction: column;

            .header {
              font-weight: 700;
              font-size: 14px;
              line-height: 40px;
              color: #ffffff;
              opacity: 0.47;
            }

            a {
              display: flex;
              align-items: center;
              font-weight: 400;
              font-size: 14px;
              line-height: 36px;
              color: #ffffff;
              cursor: pointer;

              &:hover {
                color: #c4c4c4;
              }
            }

            .desc {
              display: flex;
              align-items: center;
              font-weight: 400;
              font-size: 13px;
              line-height: 30px;
              color: #ffffff;

              svg {
                font-size: 1rem;
                margin-right: 0.5rem;
              }
            }
          }
        }

        &-2 {
          width: 25%;

          .title {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
            color: #ffffff;
          }

          .about {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #ffffff;
            opacity: 0.4;
            text-align: justify;
          }
        }
      }

      @media (max-width: 978px) {
        .contact-us {
          flex-direction: column;
          align-items: center;

          .info-1-details {
            align-items: center;
          }

          .info {
            width: 100% !important;
          }

          .desc {
            margin-bottom: 0.5rem;
          }

          .address {
            flex-direction: column;
            text-align: center;

            svg {
              position: relative;
              top: 1rem;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .landing-page-container {
      .banner .section {
        width: 100%;
      }
    }

    .testimonials {
      margin: 0;

      .title {
        width: 100% !important;
        font-size: 22px;
      }

      .card {
        width: 70% !important;
      }

      .card-testimonial {
        width: 75% !important;
      }
    }

    .available-jobs {
      h1 {
        width: 100% !important;
      }

      .jobs-container {
        display: flex;
        flex-direction: column;

        .s-1,
        .s-2 {
          width: 100% !important;
        }
      }
    }

    .awssld.awssld--cubeAnimation {
      display: none;
    }

    .comp-looking-for {
      padding: 0;
    }

    .looking-for-talent {
      margin: 0;
      border: none;

      .title {
        font-size: 22px;
        width: auto;
      }
    }

    .why-findpro {
      padding: 0;

      .why-container {
        padding: 2rem;
        flex-direction: column;
        align-items: center;

        & .split {
          top: 0rem;
          opacity: 0.12;
          height: 1px;
          width: 90%;
          margin: 2rem 0;
        }

        .why-tiles {
          text-align: center;
          position: relative;

          img {
            left: 0;
          }

          p {
            text-align: center;

            &.header {
              font-size: 28px;
            }
          }
        }
      }
    }

    .footer {
      img {
        top: -2.5rem;
      }

      .footer-section-1 {
        flex-direction: column;
        padding: 2.5rem 4rem;

        .s-s1 {
          padding-bottom: 2rem;
        }
      }
    }
  }

  .contact-us-a {
    color: red;
    text-decoration: none;
    border-bottom: 1px solid green;
  }

  @media (max-width: 978px) {
    .footer {
      .footer-section-1 {
        align-items: center;

        .s-s1 {
          width: auto;
          font-size: 22px;
          text-align: center;
        }

        .s-s2 {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .animate__animated.animate__fadeInLeft {
            text-align: center;
          }
        }
      }
    }

    .latest-blogs {
      padding: 0;

      .title {
        width: 100% !important;
        font-size: 22px;
      }

      .slider-container,
      .slick-slider {
        padding: 0;
        margin-top: 5rem !important;

        .slick-prev {
          left: 0;
        }

        .slick-next {
          right: 0;
        }
      }
    }

    .testimonials {
      .description {
        font-size: 14px;
      }

      .card-bg {
        margin-top: 1.5rem;
      }

      .card-testimonial {
        margin-top: 3rem;
      }

      .slick-slider {
        .slick-prev {
          left: -2rem;
        }

        .slick-next {
          right: -2rem;
        }
      }
    }

    .why-findpro {
      .why-container {
        box-shadow: none;
        border: none;
      }

      .header {
        font-size: 22px;
        padding: 0 2rem;
      }
    }

    .available-jobs {
      margin: 0;
      padding: 2rem;

      h1 {
        font-size: 22px;
        font-weight: 600;
      }
    }

    .top-notch-talents {
      padding: 2rem;

      .title {
        font-size: 22px;
      }

      .slick-slide {
        .tiles {
          width: 13rem !important;
        }
      }
    }

    .landing-page-container {
      h1 {
        font-size: 2.75rem;
      }

      .banner {
        &.view-1 {
          background-image: url("../../images/mob.png");
        }

        &.view-2 {
          background-image: url("../../images/mob.png");
        }

        &.view-3 {
          background-image: url("../../images/mob.png");
        }

        .section {
          width: 100%;
          padding: 2rem;

          @media (max-width: 599px) {
            _min-height: 130vh;
            min-height: 100vh;
          }
        }
      }

      .star-img {
        display: none;
      }

      .section-1 {
        padding: 2rem 1rem;

        .slider-container {
          left: 0;

          .slick-slider {
            padding: 0;
          }
        }
      }

      .sub {
        padding-right: 3rem !important;
      }

      .sub.sub-s3 {
        flex-direction: column;
        align-items: flex-start;
        button {
          margin-bottom: 1rem;
          width: 100%;
        }
      }
    }

    .work-flow-container {
      margin: 2rem;

      button {
        margin: 0 !important;
      }

      .tab-info {
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
          width: 100% !important;
        }

        .header {
          font-size: 22px;
          padding: 0;
        }
      }
    }

    .footer-section-2 {
      position: relative;

      .info {
        flex-direction: column;

        .info-1 {
          width: 100%;
        }

        .info-2 {
          width: 100%;
          text-align: center;
        }
      }

      .copy-right {
        text-align: center !important;
      }
    }

    .looking-for-talent {
      display: flex;
      flex-direction: column;

      .sub-title {
        text-align: center;
      }
    }

    .available-jobs {
      .job-list {
        padding: 0 !important;
      }

      .slick-prev {
        left: 0 !important;
      }

      .slick-next {
        right: 0 !important;
      }
    }
  }

  @media (max-width: 599px) {
    .footer-section-2 {
      padding: 2rem;

      .info {
        .info-1 {
          flex-direction: column;
          align-items: center;

          &-details {
            align-items: center;
          }
        }
      }
    }

    .work-flow-container {
      .nav-pills {
        width: 100% !important;
        height: 2.5rem;

        .nav-link {
          padding: 0.5rem;
        }
      }
    }
  }

  .about-video {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5rem 0;

    .video-container {
      height: 22rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: url("../../images/intro_video_bg.png") no-repeat 100% 100%;
      background-position: center;
      width: 60%;
      border-radius: 0;
      padding: 2rem;

      @media (max-width: 768px) {
        width: 85%;
      }

      .i-frame {
        height: 100%;
        width: 90%;
      }

      img {
        cursor: pointer;
      }

      p {
        font-weight: 600;
        font-size: 24px;
        color: #ffffff;
        margin: 1rem 0;
      }
    }
  }
  .find-talent {
    z-index: 1;
    margin-bottom: 2rem;

    input {
      width: 16rem;
      padding-right: 2rem !important;
      border: 1px solid gray;
    }

    button {
      position: absolute;
      top: -2px;
      padding: 0.8rem;
      left: 17rem;
      width: 12rem;

      &:hover {
        box-shadow: none;
      }

      @media (max-width: 599px) {
        width: 100%;
        position: relative;
        left: 0;
        top: 2rem;
      }
    }

    .invalid-feedback {
      position: absolute;
    }
  }

  @media (max-width: 1400px) {
    .landing-page-container {
      h1 {
        font-size: 2.75rem;
      }
    }
  }
}

.clienteleContainer,
.partnerContainer {
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  max-width: 80vw;
  margin: auto;
  @media (min-width: 2000px) {
    max-width: 1800px;
  }
}

#partnerContainerTitle {
  color: #9a9a9a;
  text-align: center;
  letter-spacing: 0.3rem;
  @media (max-width: 978px) {
    font-size: 1rem;
    letter-spacing: 0.1rem;
  }
  @media (max-width: 400px) {
    font-size: 0.8rem;
  }
}

.landingPageSectionTitle {
  font-weight: 400;
  text-align: center;
  @media (max-width: 978px) {
    font-size: 1.3rem;
  }
  @media (max-width: 400px) {
    font-size: 1rem;
  }
}

.clienteleDiv,
.partnerDiv {
  display: flex;
  justify-content: space-evenly;
}

.partnerDiv img {
  max-width: 200px;
  object-fit: contain;
  @media (max-width: 1700px) {
    max-width: 50%;
    object-fit: scale-down;
  }
  @media (max-width: 1200px) {
    max-width: 30%;
  }
  @media (max-width: 800px) {
    max-width: 20%;
  }
}

.clienteleDiv img {
  object-fit: none;
  @media (max-width: 1900px) {
    max-width: 10%;
    object-fit: scale-down;
  }
}

.clienteleDiv img {
  filter: grayscale(100%);
}

.centerTitle {
  @media (max-width: 978px) {
    width: 80%;
    margin: auto;
  }
}
