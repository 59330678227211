.candidates-sign-up {
  .form-control.is-valid,
  .was-validated .form-control:valid,
  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    background: none;
    _border: 1px solid #ced4da;
  }
  svg path {
    fill: gray;
  }

  .subs-info {
    min-height: auto;
    svg path {
      fill: #53b553;
    }
  }

  .row {
    & .banner {
      background: url("../../../images/login-signup-banner.svg");
      box-shadow: inset -11px 4px 36px rgba(218, 229, 255, 0.3);
      border-radius: 0;
      background-repeat: no-repeat;
      background-size: 100%;
      // background-size: cover;
      background-position-x: right;
      min-height: 100vh;
      height: auto;

      &__logo {
        img {
          border-radius: 0;
        }
      }

      &__title {
        font-weight: bold;
        font-size: 36px;
        line-height: 54px;
        color: #fff;
      }

      &__description {
        // font-size: 16px;
        // line-height: 28px;
        // padding: 0 5rem;

        color: #fff;
        font-size: 36px;
        padding: 1rem 0;
        line-height: 1.5;
      }
    }
  }
  .form-control {
    padding: 0.75rem;
    border-radius: 0;
  }
  .splitter {
    hr {
      bottom: -30px;
    }
    span {
      padding: 0 1.5rem;
      background: #fff;
      position: relative;
      color: #ababab;
    }
  }
  .work-status {
    @media screen and(max-width: 992px) {
      flex-direction: column;
    }

    &__item {
      border: 1px solid #efefee;
      box-sizing: border-box;
      border-radius: 0;
      padding: 10px;
      cursor: pointer;

      &:hover,
      &.active {
        background: #fff4e3;
        border: 1px solid #fff4e3;
        .ic-wrapper {
          background: #1c81ff;

          svg path {
            stroke: #fff;
          }
        }
      }
    }

    .ic-wrapper {
      width: 60px;
      height: 60px;
      margin-right: 8px;
      border-radius: 0;
      background: #f2f2f2;
      transition: 1s ease;
    }
  }

  .t-c {
    font-size: 13px;
    line-height: 19px;
    color: #808080;
  }

  .sign-in-up {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    h3 {
      display: flex;
      align-items: baseline;
      justify-content: center;

      @media (max-width: 978px) {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 2rem;
      }
      .login-as {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      span {
        color: #8d8d8d;
        font-size: 14px;
        font-weight: normal;
      }
      .dropdown {
        button {
          font-size: 14px;
          color: #1c81ff !important;
          padding-left: 0.75rem;
        }
        .dropdown-item {
          color: #000000;
        }
      }
    }

    .sigin-in {
      font-size: 13px;
      color: #8d8d8d;

      @media (min-width: 991px) {
        margin-right: 7rem;
      }
    }
  }

  a {
    color: #1c81ff;
    text-decoration: none;
  }
}
.form-select.is-invalid {
  border-color: #dc3545 !important;
  &:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem #dc354540 !important;
  }
}

button {
  &.rewmp-btn {
    background-color: #1c81ff;
    color: #fff;
    border-radius: 0;
    padding: 1rem;
  }
  &.rewmp-btn-secondary {
    background-color: #222222;
    _color: #000000;
    a {
      color: #fff;
    }
  }
  &.btn-light {
    &:hover {
      box-shadow: 0 0 0 0.25rem #d3d4d580;
    }
  }
  &:disabled {
    background-color: #1c81ff !important;
    opacity: 0.4;
    color: white !important;
  }
  &.rewmp-btn-trans {
    background-color: transparent;
    color: #202020;
    border: none;
    &:hover {
      background-color: transparent !important;
      box-shadow: none !important;
      color: #d4d4d4;
    }
  }

  &.proceed-button {
    background-color: #1c81ff;
    color: white;
  }
  &.cancel-button {
    background-color: #d3d4d580;
  }
}
.with-bg {
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 0;
  box-shadow: none;
}

.field-info {
  font-size: 12px;
  color: gray;
}

.password-validation-message {
  span {
    &::after {
      content: ", ";
    }

    &:last-child {
      &::after {
        content: " required.";
      }
    }
  }
}

.resume-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile {
  padding: 35px;
  height: auto;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 0;
  font-family: "Poppins";
  margin-bottom: 2rem;

  @media (max-width: 599px) {
    padding: 1rem;
    .revamp-table__header,
    .revamp-table__row {
      font-size: 12px;
    }
  }

  .resume-sections-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;

    .resume-sections {
      background: #f4f4f4;
      border: 1px solid #efefee;
      box-sizing: border-box;
      border-radius: 0;
      padding: 6px 12px;
      margin: 0.5rem 0.5rem 0.5rem 0;
      display: flex;

      img {
        margin-right: 0.5rem;
      }
    }
  }
  .p-header {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }
  .p-sub-header {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
    opacity: 0.47;
    margin-top: 1rem;
  }
}
.bid-value {
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  span {
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #a3a3a3;
  }
}
.job-alert-container {
  justify-content: center;
  .job-card {
    max-width: 100%;
    flex-basis: 100%;

    .revamp-table {
      &__header,
      &__row {
        justify-content: flex-start;
        & *,
        .job-date {
          width: 33%;
        }
      }
    }
  }
}

.header-new {
  font-weight: 600;
  font-size: 32px;
  color: #222222;
}
.h-120 {
  height: 100px !important;
}

.invoice-component {
  .header {
    font-weight: 600;
    font-size: 32px;
    color: #222222;
    margin-top: 1.5rem;
    @media (max-width: 678px) {
      font-size: 20px;
      margin-top: 1rem;
    }
  }
  .payment-tile {
    box-shadow: none;
    box-sizing: border-box;
    padding: 0;
    min-width: 300px;
    // width: 90%;
    margin-bottom: 1.25rem;
    padding: 1rem;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 0;

    &:hover {
      .wrapper .rewmp-btn {
        visibility: visible;
      }
    }

    .wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      &.header {
        font-weight: 600;
        font-size: 20px;
        color: #222222;
        margin-bottom: 10px;
      }
      .rewmp-btn {
        background: #222222;
        visibility: hidden;
        &:hover {
          color: #ffffff;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .dez-bnr-inr.dez-bnr-inr-sm,
    .dez-bnr-inr {
      height: 250px;
    }
    .payment-tile {
      width: 100%;
    }
  }
}
.manage-payments {
  .revamp-table {
    &__header,
    &__row {
      justify-content: flex-start;
      & * {
        width: 20%;
        padding: 0.5rem;
      }
    }

    button {
      width: 116px;
      font-size: 14px;
      height: 36px;
      padding: 0;
    }
  }
}
.payment-details {
  .MuiCard-root.css-1fuvib6-MuiPaper-root-MuiCard-root {
    box-shadow: none;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 0;
    padding: 0;
  }

  .search-container {
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #d9d9d9 !important;
    border-radius: 0;
    padding-left: 0.5rem;
    width: 100% !important;

    .form-control {
      box-shadow: none !important;
      border: none !important;
    }

    img {
      width: auto;
      margin-right: 0;
      padding-right: 0 !important;
    }
  }
}
.revamp-table {
  display: flex;
  flex-direction: column;

  &__header {
    background: #f5f5f5;
    border-radius: 0;
    font-weight: 600;
  }

  &__header,
  &__row {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem;

    div,
    span {
      width: 40%;
      &:last-child {
        width: 20%;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  &__nodata {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.summary-list {
  display: flex;
  justify-content: space-between;
  padding-right: 5rem;
  margin-top: 1rem;

  > div {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #eaeaea;
    padding: 0 2rem;
    width: 100%;
    &:first-child {
      padding: 0;
    }
    &:last-child {
      border: none;
      padding-right: 0;
    }
  }
  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #888888;
  }
  .description {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  @media (max-width: 978px) {
    flex-direction: column;
    padding-right: 0;

    & * {
      font-size: 12px !important;
    }

    .list-item {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      padding: 0;
      border: none;
      margin-bottom: 1rem;
      .description {
        text-align: right;
      }
    }
  }
}

.revamp-input {
  .MuiInput-root,
  .css-1s2u09g-control,
  .css-1pahdxg-control {
    background: #ffffff;
    border: 1px solid #adadad !important;
    box-sizing: border-box;
    border-radius: 0;
    padding: 0.5rem !important;
    outline: none !important;
    box-shadow: none !important;

    &::before,
    &::after {
      border: none !important;
    }
  }
}

.phone-input {
  &::before {
    content: "(+1)";
    position: absolute;
    font-size: 13px;
    top: 48px;
    color: #9aa2aa;
    left: 20px;
  }
  input {
    padding-left: 2.25rem !important;
  }
}

.add-skills {
  .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
  .selected {
    background: #fef5ee;
    border: 1px solid #0404044a !important;
  }
  .actions {
    position: relative;
    top: 8px;

    button {
      background: transparent !important;
      display: flex;
      align-items: center;
      padding: 0.5rem !important;
    }
  }
  .known-skills {
    display: flex;
    flex-wrap: wrap;
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #898989;
      border: 1px solid;
      border-radius: 0;
      padding: 0.5rem;
      margin: 0.5rem 0.5rem 0 0;
      cursor: pointer;
    }
  }
}

.content-editable {
  border: 1px solid #b7b7b7 !important;
  border-radius: 0;
  height: 50vh;
  overflow-x: auto;
  padding: 1rem;

  .placeholder {
    color: #b7b7b7;
    display: block;
    font-size: 13px;
  }

  &:focus-visible {
    border: 1px solid #b7b7b7 !important;
    outline: none;

    .placeholder {
      display: none;
    }
  }
}

.my-account {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  .acc-container {
    @media (max-width: 599px) {
      padding: 0 !important;
      margin-top: 1rem;
      .intro-container div {
        width: 100%;
      }

      .profile {
        button {
          padding: 0.25rem !important;
          font-size: 14px !important;
          border-radius: 0;
        }
        img {
          width: 5rem;
        }
      }
    }
  }
}
.round-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  div {
    background: #ffffff !important;
    width: 100% !important;
    height: 100% !important;
    border-radius: 0;

    img {
      width: 60% !important;
      height: 60% !important;
    }
  }

  &.avlble-jobs {
    img {
      width: 60px !important;
    }
  }

  &.user-img {
    img {
      width: 80% !important;
      height: 80% !important;
      border-radius: 0;
    }
  }

  &.job-detail-comp.no-img {
    img {
      width: auto !important;
      height: auto !important;
    }
  }

  &.profile-img {
    img {
      width: 60% !important;
      height: 60% !important;
    }

    &.no-img {
      img {
        width: auto !important;
        height: auto !important;
      }
    }
  }
}
.accordion-container {
  &::before {
    display: none;
  }
  .MuiButtonBase-root.MuiAccordionSummary-root {
    background: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 0;
    pointer-events: none;
  }
  .filter-label {
    .MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root {
      padding-left: 0.5rem;
    }
  }
}

.show-skills {
  padding-left: 2rem;
  color: #1c81ff;
  cursor: pointer;
}

.search-input {
  &.keyword {
    .MuiOutlinedInput-root.MuiInputBase-root {
      padding: 0;
    }

    @media (max-width: 978px) {
      width: 100% !important;
      border: none;
    }
  }
}

.dropdown {
  button {
    background: transparent !important;
    color: #202020 !important;
    border: none;
    outline: none;
    box-shadow: none !important;

    &:hover,
    &:active,
    &:focus {
      background: transparent !important;
      border: none;
      outline: none;
      box-shadow: none !important;
      color: #202020 !important;
    }
  }
  .dropdown-toggle {
    &::after {
      border: solid #989898;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      background: transparent;
      position: relative;
      left: 6px;
      top: 1px;
    }
    &:hover,
    &:focus {
      &::after {
        border: solid black;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        background: transparent;
      }
    }
  }
}

.pagination {
  overflow-x: auto;
  margin: 1rem;
}

.pagination > li > a {
  cursor: pointer;
}
.pagination > li.disabled > a {
  color: #ccc !important;
}

.pagination > li > a:hover {
  background-color: #1c81ffbf !important;
  color: #fff !important;
}
.pagination > li > a:focus {
  background-color: transparent !important;
  border: 1px solid #efefef !important;
  color: #767676 !important;
}

.pagination > li.active > a {
  background-color: #1c81ffbf !important;
  color: #fff !important;
}

.vendor-jd {
  .candidates-length {
    width: 37px;
    height: 22px;
    background: #d2d2d2;
    border-radius: 0;
    color: #fff;
    text-align: center;
    position: relative;
    top: 12px;
    font-size: 15px;
    left: -8px;

    &.active {
      background: #1c81ff;
    }
  }
  .applied-can-tab .MuiTabs-indicator {
    width: 204.062px !important;
  }

  .total-profiles {
    background: #f7f7f7;
    border-radius: 0;
    padding: 12px;
    margin-top: 2.5rem;
    color: #353535;
    display: flex;
    align-items: center;
  }

  .add-candidate {
    display: flex;
    justify-content: flex-end;

    button {
      padding: 0.5rem;
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }

  .list-item {
    &.vendors-list-item {
      justify-content: unset !important;

      .info {
        width: 50% !important;
        text-align: left;
      }

      .rewmp-btn {
        visibility: visible !important;
        opacity: 1 !important;
        display: flex;
        background: transparent;
        color: #1c81ff;
        align-items: center;
        border-color: transparent !important;
        box-shadow: none;
      }
    }
  }
}

.table-job-bx {
  .vendors-other-resume {
    margin-top: 2rem;

    th {
      background-color: #f5f5f5;
      color: #000000de;
      font-weight: 600;
      font-size: 16px;
    }
    tbody tr {
      border-bottom: 1px solid #f5f5f5;
    }
    td {
      background-color: #fff;
      border: none;
      font-size: 1rem;
    }
  }
}

.admin-manage-people {
  .dropdown-toggle {
    font-weight: bold;
  }
  .candidates-info-list {
    list-style: none;
    margin-top: 1rem;

    li {
      margin: 1rem 0;
      display: flex;
      align-items: center;

      span {
        width: 50%;
      }
    }

    @media (max-width: 1200px) {
      li {
        margin: 0;
        flex-direction: column;
        align-items: flex-start;

        span {
          width: auto;
          margin-top: 0.5rem;
        }
      }
    }
  }

  .job-view-component {
    .job-details-btn {
      position: relative;
      top: 0;
    }
    .job-tile {
      .MuiBox-root {
        min-height: 172px;
      }
    }
  }

  .card-actions {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    top: 8rem;
    visibility: visible;
    opacity: 1;

    .tooltip {
      top: auto !important;
    }

    .switch.btn {
      width: 6rem;
      margin: 1rem;
      border-radius: 0;
      font-size: 1rem;

      .switch-handle {
        border-radius: 0;
        position: relative;
      }

      &.btn-success {
        .btn-success {
          font-size: 13px;
          padding-right: 6rem;
        }

        .switch-handle {
          right: 1.05rem;
          &::before {
            content: "\2714";
            font-size: 1.5rem;
            position: relative;
            left: -10px;
            top: -6px;
            color: green;
          }
        }
      }

      &.btn-danger {
        .btn-danger {
          font-size: 13px;
          padding-left: 2.25rem;
        }

        .switch-handle {
          left: 1.05rem;
          &::before {
            content: "\2A2F";
            font-size: 2rem;
            position: relative;
            left: -10px;
            top: -12px;
            color: red;
          }
        }
      }
    }

    svg {
      cursor: pointer;
    }
  }
  .job-list .job-tile .job-button .job-details-btn:disabled {
    cursor: no-drop;
    a {
      color: #857e7e !important;
    }
  }
  .job-list .job-tile .button-group {
    visibility: visible !important;
    opacity: 1 !important;
  }
}
.h-m-resp {
  @media (max-width: 678px) {
    margin-top: 0 !important;
    padding-left: 10px !important;
  }
}

.h-m-resp-p-l-10 {
  @media (max-width: 678px) {
    padding-left: 10px !important;
  }
}

.table-vendor-requests {
  th {
    background: #1c81ff;
    color: #fff;
    font-weight: 600;
  }
  th,
  td {
    font-size: 14px;
    text-align: center;
  }

  tbody tr:nth-of-type(even) {
    background: rgba(0, 0, 0, 0.06);
  }
}

.admin-vendor-info {
  padding: 2rem 1rem;
  p {
    text-align: left;
    color: #808080;
    font-size: 14px;
  }
}

.subs-banner {
  height: 60px;
  top: 91px;
  background: #cfe2f3;
  border: 1px solid #e7e7e7;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;

  &.profile-view,
  &.job-posts {
    margin: 0;
    margin-bottom: 1rem;
  }

  .subs-info {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  button {
    padding: 0.5rem;
    margin-top: 0 !important;
    font-size: 14px;
  }
}

.subscription-status {
  position: absolute;
  background: green;
  color: #fff;
  transform: rotate(-45deg);
  left: -60px;
  font-size: 12px;
  padding: 4px 35px;
  text-align: center;
  top: -6px;

  @media (max-width: 599px) {
    min-width: auto !important;
    left: -2.5rem;
    top: 1rem;

    & ~ .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-8.MuiGrid-grid-md-9 {
      position: relative;
      top: 2rem;
    }
  }
}

.features {
  padding: 2rem;

  &-title {
    font-weight: 600;
    font-size: 16px;
    color: #222222;
  }

  &-desc {
    font-size: 13px;
    color: #3d3d3d;
    opacity: 0.67;
  }

  svg {
    color: #1c81ff;
    position: relative;
    top: 2px;
    left: -4px;
  }
  div:last-child hr {
    display: none;
  }
}
.f-w-600 {
  font-weight: 600;
}
.f-s-32 {
  font-size: 32px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
}
.manage-job-choose-can {
  .MuiPaper-root.MuiDialog-paper {
    min-width: 40vw !important;
  }
  .dez-bnr-inr-entry {
    h1,
    p {
      display: none;
    }

    .MuiPaper-root.MuiPaper-elevation {
      box-shadow: none;
      border: 1px solid #d9d9d9;
      border-radius: 0;

      fieldset {
        border: none;
      }

      .MuiOutlinedInput-root.MuiInputBase-root {
        padding: 0;
      }

      button.MuiButtonBase-root.MuiIconButton-root {
        padding-right: 0;
        &:last-child {
          padding-left: 0.75rem;
        }
      }
    }
  }

  @media (max-width: 599px) {
    .MuiDialogContent-dividers {
      padding: 1rem !important;
    }
    .file-info {
      overflow-x: scroll;
      .can-info {
        width: 25rem;
      }
    }
  }
}
.d-center {
  display: flex;
  align-items: center;
}

.subscribe-payment-comp {
  @media (max-width: 796px) {
    .dez-bnr-inr-entry {
      justify-content: center;
      display: flex;
      flex-direction: column;
      height: 207px;

      .breadcrumb-row {
        position: relative !important;
      }
    }
  }
}
.employer-wrapper {
  @media (max-width: 599px) {
    .MuiAccordionDetails-root.filters {
      max-height: 30vh;
      overflow: scroll;
    }
  }
  .candidates-list-container {
    @media (max-width: 599px) {
      .list-items .list-item .bid-value {
        text-align: left !important;
      }
      .emp-profile-actions {
        position: absolute;
        right: -1.5rem;
        bottom: 8rem;
      }
      .video-intro {
        display: block;
        text-align: left;
        width: 100%;
      }
    }
    @media (max-width: 978px) {
      .list-items {
        position: relative;
        .rewmp-btn {
          visibility: visible;
          opacity: 1;
        }
        hr {
          margin: 2rem 0;
        }

        h4 {
          text-align: left !important;
        }
        .emp-profile-actions {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

.profileDetails {
  display: flex;
  flex-direction: column;
  justify-items: center;
  row-gap: 1rem;
}

.profileDetails button {
  align-self: flex-end;
}

.profileDetails .infoChipDiv {
  display: flex;
  column-gap: 0.5rem;
  @media (max-width: 550px) {
    flex-direction: column;
    row-gap: 0.5rem;
    align-items: flex-end;
  }
}

.profileDetails .infoChipDiv .MuiChip-root {
  background-color: #8ec3e6;
  font-weight: 600;
  @media (max-width: 550px) {
    max-width: 70%;
    height: auto;
  }
}

.profileDetails .infoChipDiv .MuiChip-label {
  @media (max-width: 550px) {
    display: block;
    white-space: normal;
  }
}

#profileIncompleteAlert {
  border-radius: 0;
  text-align: justify;
  font-size: 1rem;
  border: 1px solid lightgrey;
}

.titleCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 6rem;
  max-width: 1150px;
  margin: auto;
  padding-top: 1rem;
  @media (max-width: 1200px) {
    padding: 1rem 1.5rem;
    margin: 0;
  }
  .title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    @media (max-width: 767px) {
      font-size: 1.3rem;
    }
  }
  .subTitle {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.47);
  }
  .breadcrumb-row {
    padding: 0;
    background: none;
  }
  .noSubTitle {
    padding-top: 1rem;
  }

  .clickableBreadcrumb {
    cursor: pointer;
    position: relative;
    z-index: 1000;
  }
}

.body-spacing {
  @media (max-width: 1200px) {
    max-width: 1200px !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}

.upper-spacing {
  padding-top: 30px;
}

.vendor-manage-candidates {
  .sub-section {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
}

.app-logo {
  @media (min-width: 600px) {
    position: relative;
    right: 0.5rem;
  }
}

#overlay-container {
  #overlay-div {
    background-color: grey; // light grey- #d3d3d3
    padding: 2rem;
  }
  #overlay-close-icon {
    display: flex;
    margin-left: auto;
    position: relative;
    top: 1.5rem;
    left: 0.4rem;
    cursor: pointer;
  }
  #overlay-iframe {
    width: 100vw;
    height: 100vh;
  }
}

.MuiOutlinedInput-notchedOutline,
.MuiChip-root {
  border-radius: 0px !important;
}
