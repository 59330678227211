.intro-container {
  div {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #efefee;
    box-sizing: border-box;
    border-radius: 0;
    padding: 0.5rem 0.5rem 0.5rem 1rem;

    span {
      font-size: 13px;
      line-height: 20px;
      color: #808080;
    }
  }
}
.prefix-dollar {
  &::before {
    content: "$";
    position: absolute;
    left: 8px;
    top: 44px;
    color: #8d8d8d;
  }
}
.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
  border-color: #e1e6eb !important;
}
.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
  box-shadow: none !important;
}

input[type="month"] {
  position: relative;
  &::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 8px;
    width: 98%;
    background-position-x: 100%;
    padding: 12px 0;
  }
}

.form-control {
  padding: 0.75rem !important;
  border-radius: 0;
}
select.form-control:not([size]):not([multiple]) {
  height: auto !important;
}
