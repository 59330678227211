.header-container {
  height: 5.9375rem;
  background: #fff;
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 7.5rem;
  top: 0;
  z-index: 999;
  box-shadow: 0 3px 5px gray;

  > div {
    width: 50%;
    display: flex;
    align-items: center;

    &.sec-2 {
      justify-content: flex-end;
    }
  }

  @media (max-width: 1200px) {
    padding: 0.5rem;
  }

  @media (max-width: 978px) {
    padding: 2rem;

    .sec-1 {
      width: 100%;
    }
  }

  img {
    width: 9.5rem;

    &.menu {
      width: 1.5rem;
      cursor: pointer;
    }
  }

  .menu-items {
    position: absolute;
    top: 3.75rem;
    background: #ffffff;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 100vw;
    right: -2rem;
    height: 100vh;

    .MuiTabs-root {
      padding: 0;
      margin: 1rem 0;
      height: auto;

      .MuiTabs-indicator {
        display: none;
      }

      a {
        padding: 0;
      }
    }
  }

  button {
    font-size: 14px;
  }

  .split {
    height: 1.5rem;
    width: 2rem;
    border-right: 1px solid #c8c8c8;
  }

  .MuiTabs-root {
    height: 94px;
    display: flex;
    align-items: end;
    padding-left: 2rem;

    a {
      padding-bottom: 2.25rem;
      text-transform: none;

      &.Mui-selected {
        color: #1c81ff;
      }
    }

    .MuiTabs-indicator {
      background-color: #202020 !important;
      height: 2.5px;
      display: none;
    }
  }
}
.mrgn-l-1rm {
  margin-left: 1rem;
}
.login-as {
  display: flex;
  justify-content: center;

  @media (max-width: 599px) {
    flex-direction: column;
    align-items: center;

    button {
      width: 100%;
    }
  }

  .btn-login {
    display: flex;
    align-items: center;
    width: 154px;
    height: 128px;
    background: #fefefe;
    border: 1px solid #dfdfdf;
    border-radius: 0;
    color: #000000;
    flex-direction: column;
    justify-content: space-around;

    &.active,
    &:hover {
      background: #f4f4f4 !important;
      border-radius: 0;
    }
    &.active {
      border: 1px solid #1c81ff !important;
      box-shadow: none;
    }
  }
}
.aln-itm-end {
  align-items: flex-end !important;
}

// .MuiPaper-root.MuiDialog-paper {
//   min-width: 30vw !important;
// }

.search-container {
  width: 252px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 0;
  display: flex;
  align-items: center;
  padding: 0 1rem;

  input {
    border: none;
    outline: none;
    width: 90%;
    visibility: visible;
    opacity: 1;
    transition: visibility 1s, opacity 1s, width 1s linear;
  }

  svg {
    position: relative;
    top: 3px;
    cursor: pointer;
  }

  &.no-search-text {
    border: none;
    input {
      visibility: hidden;
      opacity: 0;
      transition: visibility 1s, opacity 1s, width 1s linear;
    }
  }
}

.landing-login {
  button {
    background: transparent !important;
    color: #202020 !important;
    border: none;
    outline: none;
    box-shadow: none !important;

    &:hover,
    &:active,
    &:focus {
      background: transparent !important;
      border: none;
      outline: none;
      box-shadow: none !important;
      color: #202020 !important;
    }
  }
  .opt {
    display: flex;
    align-items: center;
  }
  .dropdown-menu {
    padding: 0.5rem;
    background: #ffffff;
    border-radius: 0;
    margin: 1rem 0 0 1rem;
    left: -13rem;
    top: -1rem;
    @media (max-width: 599px) {
      margin: 1rem 0 0 -1.5rem;
    }

    @media (max-width: 978px) {
      left: -4rem !important;
      top: 0;
    }
  }
  .dropdown-item {
    display: flex;
    align-items: center;
    background: #fefefe;
    border: 1px solid #dfdfdf;
    border-radius: 0;
    padding: 0.5rem 1rem;
    justify-content: space-between;
    width: 245px;
    font-weight: 500;
    margin-bottom: 0.75rem;

    &:active {
      background: transparent !important;
      box-shadow: none !important;
      color: #202020 !important;
    }

    &:last-child {
      margin: 0;
    }
  }
}
