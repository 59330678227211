.job-details {
  padding-top: 1.5rem;
  .section-1 {
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 0;
    padding: 2rem;
    position: relative;
    .job-applied {
      bottom: 8rem;
    }

    .MuiChip-root {
      top: 2rem;
      right: 0px;
    }
  }
  .employer-logo {
    width: auto;
    height: auto;
    object-fit: contain;
  }

  .job-title {
    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      a {
        color: #000000;
      }
    }
    h6 {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #1c81ff;
    }
  }

  .job-description {
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #676767;
  }
  .section-2 {
    .apply-now {
      padding: 25px;
      background: #ffffff;
      border: 1px solid #eaeaea;
      box-sizing: border-box;
      border-radius: 0;
      &__title {
        font-weight: 600;
        font-size: 18px;
        color: #000000;
      }
      &__desc {
        font-weight: 400;
        font-size: 15px;
      }
      .bid-value {
        font-weight: 500;
        font-size: 18px;
        color: #000000;
        span {
          font-weight: 400;
          font-size: 13px;
          line-height: 24px;
          color: #a3a3a3;
        }
      }
      &__btn {
        background-color: #1c81ff;
        color: #fff;
        border-radius: 0;
        padding: 1rem;
        outline: none;
        border: none;

        &:disabled {
          background-color: #1c81ff !important;
        }

        &:hover,
        &:active,
        &:focus {
          box-shadow: 0 0 0 0.25rem #d3d4d580 !important;
          background-color: #1c81ff !important;
        }
      }
    }

    .social-contacts {
      padding: 25px;
      background: #ffffff;
      border: 1px solid #eaeaea;
      box-sizing: border-box;
      border-radius: 0;
      word-break: break-word;
    }
  }
}
