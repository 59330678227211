button.MuiButton-root {
  background-color: #1c81ff;
  text-transform: none;
  border-radius: 0;
  box-shadow: none !important;

  &:hover {
    background-color: #1c81ff;
  }
}
.body-container {
  .css-1iu83m2-MuiContainer-root {
    padding-top: unset;
    margin-top: unset;
  }
}

.card-inner-cap {
  background: url("../images/banner.svg") no-repeat 100%;
  height: 106px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  width: 100%;
  top: 80px;

  .header-new2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    color: #222222;
    padding-left: 8.5rem;
    margin-top: 2rem !important;
    @media (max-width: 1420px) {
      padding-left: 5.5rem;
    }
    @media (max-width: 1400px) {
      padding-left: 5rem;
    }
    @media (max-width: 1300px) {
      padding-left: 2.5rem;
    }
    @media (max-width: 1240px) {
      padding-left: 0;
    }

    @media (max-width: 678px) {
      padding-left: 0;
      font-size: 20px;
      margin-top: 1rem !important;
      p {
        padding-left: 0.3rem !important;
      }
    }
  }

  .job-counts-container {
    padding-right: 10rem;
    display: flex;

    .split {
      height: 19px;
      opacity: 0.17;
      border: 1px solid #fff;
      position: relative;
      top: 12px;
      margin: 0 3rem;
      background: #fff;
    }

    .job-counts {
      display: flex;
      flex-direction: column;

      .count {
        font-weight: 600;
        font-size: 20px;
        line-height: 120%;
        _color: #fff;
      }

      .status {
        font-weight: 400;
        font-size: 13px;
        line-height: 24px;
        _color: rgba(248, 242, 242, 0.47);
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .header,
    .job-counts-container {
      padding: 0;
    }
  }

  @media screen and (max-width: 769px) {
    .job-counts-container {
      display: none;
    }
  }
}

@media screen and (max-width: 769px) {
  .card-inner-cap {
    top: 52px;
    padding-left: 1.5rem !important;
  }
}

.post-a-job {
  fieldset {
    border: 1px solid #adadad !important;
    border-radius: 0;
  }

  .multi-select-split {
    &.d-flex-between {
      display: flex;
      justify-content: space-between;
      width: "100%";
    }

    .css-q8hpuo-MuiFormControl-root {
      width: 45%;
    }

    @media (max-width: 678px) {
      &.time-zone {
        padding-top: 0 !important;

        .css-q8hpuo-MuiFormControl-root {
          margin-bottom: 0 !important;
        }
      }

      &.d-flex-between {
        display: block;
      }

      .css-q8hpuo-MuiFormControl-root {
        width: 100%;
        margin-bottom: 1.5rem;
      }
    }
  }
}

.card-cap {
  background: url("../images/banner.svg") no-repeat 100%;
  background-size: cover;

  h1 {
    color: #222222;
  }

  .MuiPaper-root {
    box-shadow: none;
    padding: 0;
    border-radius: 0;

    & > .MuiBox-root {
      border: 1px solid #d9d9d9;
      border-radius: 0;
      padding: 0.25rem 1rem;
      _padding-right: 0.5rem;

      fieldset {
        border: none;
      }
    }
  }
}

.header-sub-description {
  font-weight: normal;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.47);
}
.manage-jobs {
  box-shadow: none !important;
  background: none !important;
  border: 1px solid #eaeaea;
}
.post-a-job-container {
  background-color: transparent !important;
  box-shadow: none !important;
}
.filter-sidebar > div {
  border: 1px solid #eaeaea;
}
.filter-sidebar,
.filter-sidebar *,
.accordion-container {
  box-shadow: none !important;

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    padding-bottom: 1rem;
  }
  .MuiCardHeader-root,
  .MuiAccordion-root {
    padding: 0 1rem !important;
  }
}

.filters {
  background: transparent !important;
  box-shadow: none;

  hr {
    display: none;
  }

  &__checkbox {
    &.Mui-checked {
      color: #1c81ff !important;
    }
  }

  div {
    padding-bottom: 1rem;
  }

  .range-slider__tooltip__caret {
    top: -1rem !important;
    display: none !important;
  }

  .range-updated ~ .range-slider__tooltip {
    opacity: 1;
  }

  .range-slider__wrap {
    margin: 2rem 0;
  }

  .range-slider.range-slider--primary {
    &::before {
      content: "$0";
      position: absolute;
      top: 2rem;
      left: 0;
    }
    &::after {
      content: "$1000";
      position: absolute;
      top: 2rem;
      right: 0;
    }
  }
}

.MuiAccordionDetails-root.filters.css-u7qq7e {
  padding-left: 0px !important;
  padding-top: 16px !important;
}

.job-view-component {
  .job-tile {
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 0;
    box-shadow: none;
    padding: 1.5rem;

    @media (max-width: 599px) {
      padding: 0.5rem;

      .job-chip {
        flex-wrap: wrap;
      }
      .bid-value {
        position: relative;
        top: -20px;
      }
      .status-chip {
        top: -9.75rem !important;
      }

      .box-grid {
        flex-direction: column;
        min-height: 15rem;
        padding: 0.5rem;
        > div {
          min-width: 260px;
        }
      }
    }

    h3 {
      text-transform: capitalize !important;
    }

    h3 a {
      font-size: 18px;
      color: #000000;
    }

    h6 {
      font-size: 14px;
      color: #1c81ff;
      line-height: 1;
    }

    .job-chip {
      .MuiChip-filled {
        background: #f4f4f4;
        border: 1px solid #efefee;
        box-sizing: border-box;
        border-radius: 0;
      }
    }
  }
}

.react-tel-input {
  left: 2rem;

  .form-control {
    border-color: #e1e6eb !important;
    box-shadow: none;
    font-size: 14px !important;
    height: 46.8px;
    width: calc(100% - 39px);

    &.invalid-number {
      border-color: red !important;
      background-color: transparent !important;
    }
  }

  input:disabled:required,
  input:disabled ~ .flag-dropdown {
    background: #e9ecef !important;
  }

  .flag-dropdown {
    left: -2rem;
    background: #fff;
    border-right: none;
    border-color: #e1e6eb !important;
    border-top-left-radius: 9px !important;
    border-bottom-left-radius: 9px !important;

    &.invalid-number {
      border-color: red !important;
    }
  }

  .invalid-number-message {
    top: 3.25rem;
    left: -2rem;
  }
}

.tooltip {
  position: absolute !important;
  _top: 0 !important;
  _right: 0 !important;
}
.job-notes {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.status-chip {
  &.Rejected,
  &.Shortlisted {
    color: #fff !important;
  }
  &.Scheduled span {
    color: #000000 !important;
  }
}
.css-9yjarf {
  padding-bottom: 0 !important;
  margin-top: 1rem;
}
.css-vjkoi3 {
  padding-bottom: 0 !important;
  align-items: flex-end !important;
}
.css-1kijper-MuiButtonBase-root-MuiCheckbox-root {
  color: #c1c1c1 !important;
}
