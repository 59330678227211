.bulk-upload {
  background: #fff;
  border-radius: 0;
  min-height: 100vh;

  .m-r-5 {
    margin-right: 5rem;
  }

  @media (max-width: 370px) {
    .m-r-5 {
      margin-right: unset;
    }
  }

  .pagination {
    position: relative;
    z-index: 999;
  }
  .Mui-selected,
  a {
    color: #1c81ff !important;
  }

  .revamp-table__body {
    margin-bottom: 3rem;
  }

  .MuiTabs-indicator {
    display: none !important;
  }

  .breadcrumb-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 599px) {
      flex-direction: column;
    }

    button {
      font-size: 14px;
      padding: 0.75rem 2rem;
      margin-bottom: 2rem;
      width: 10rem;

      &.btn-dark {
        background: rgb(0, 0, 0);
        color: rgb(255, 255, 255);
      }
    }
  }

  .accordion-container .MuiButtonBase-root.MuiAccordionSummary-root {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .accordion-container
    .MuiButtonBase-root.MuiAccordionSummary-root
    .MuiAccordionSummary-content {
    margin: 0;
  }
}

.bulk-upload-modal {
  a {
    color: #1c81ff !important;
  }

  .MuiPaper-root.MuiDialog-paper {
    min-width: 40vw !important;
  }

  &.bulk-file-uploded .MuiPaper-root.MuiDialog-paper {
    min-width: 80vw !important;
  }

  .revamp-table {
    @media (max-width: 599px) {
      width: 230vw;
    }
  }

  .revamp-table__header div,
  .revamp-table__header span,
  .revamp-table__row div,
  .revamp-table__row span {
    text-align: center;
  }
  .revamp-table__row {
    input {
      width: 40%;
      border: none;
      text-align: center;
      border: 1px dotted #ccc;
      border-radius: 0;
      padding: 2px;
      margin-right: 1rem;
    }
  }
}

button.MuiButtonBase-root.MuiTab-root {
  text-transform: none;
}

.can-list {
  .avatar {
    background: #e9e9e9;
    width: 60px;
    height: 60px;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #222222 !important;
    font-weight: 600;
  }

  fieldset {
    border: none;
  }

  .state-country {
    font-weight: 400;
    font-size: 15px;
  }

  button.btn.btn-contained {
    background-color: #1c81ff;
    color: #fff;
    border-radius: 0;

    &:disabled {
      color: #343a40;
    }
  }

  .card {
    padding: 0 0.65rem;
    border-radius: 0;
  }

  .search-input.keyword {
    border: none;
  }

  .files-folders {
    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 0;
    padding: 1rem;
    margin-top: 2rem;

    .title {
      font-weight: 600;
      font-size: 18px;
      color: #000000;
    }

    .folder {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 12px 18px;
      cursor: pointer;
      color: #202020;
      background: #fff;
      opacity: 0.5;

      &.selected {
        background: #f2f2f2;
        color: #222222;
        opacity: 1;
      }

      &-name {
        display: flex;
      }
    }
  }

  .file-sort {
    font-size: 13px;

    span {
      color: #999999;
    }

    .dropdown-toggle {
      padding-bottom: 12px;
      font-size: 13px;
    }
  }

  .file-info {
    background: #ffffff;
    border: 1px solid #e7e7e7;
    border-radius: 0;
    padding: 1rem;
    margin-bottom: 2rem;

    .share-user-pic {
      width: 40px;
      height: 40px;
      position: relative;
      margin-right: 0.5rem;
      border: 2px solid #e0e0e0;
      border-radius: 0;

      &:nth-child(1) {
        left: 3rem;
      }

      &:nth-child(2) {
        left: 2rem;
      }

      &:nth-child(3) {
        left: 1rem;
      }

      &:last-child {
        margin-right: 0.35rem;
      }
    }

    .dropdown {
      background: white;
      padding: 0.5rem;
      right: -10.5rem;
      box-shadow: 0px 4px 14px #0000001a;
      border-radius: 0;
      top: 0;
      z-index: 1;

      @media (max-width: 1200px) {
        right: 0;
        top: 2rem;
      }
    }

    @media (max-width: 698px) {
      flex-direction: column;

      h4 {
        word-break: break-all;
      }
    }
  }

  .rate-resume {
    .rate {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #000000;
    }

    .resume {
      display: inline-flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #222222;
    }
    .split {
      opacity: 0.2;
      border-left: 1px solid #000000;
      display: inline-flex;
      align-items: center;
      margin: 0 0.5rem;
      height: 18px;
      position: relative;
      top: 4px;
    }
  }

  .banner-badge {
    right: 0;
    top: 0;
    background: #ffdd2d;
    border-radius: 0;
    text-transform: uppercase;
    color: #000000 !important;
    font-weight: 600;
    font-size: 12px;
    padding: 0.25rem 1rem;
  }

  .subs-banner {
    .profile-view {
      height: 2.5rem;

      .info {
        font-weight: 400;
        font-size: 15px;
        color: #000000;
      }

      button {
        background-color: transparent;
        color: #1c81ff;
      }
    }

    @media (max-width: 599px) {
      display: flex;
      height: auto;
      flex-direction: column;
      padding: 1rem;

      .subs-info {
        min-height: auto;
      }
    }
  }

  .dropdown a {
    color: #212529 !important;
  }

  @media (max-width: 599px) {
    .name-container {
      margin-top: 1.5rem;
    }
  }
}
